import { Heading as ChakraHeading, type HeadingProps } from '@/ui';

export const Heading = (props: HeadingProps) => {
  return (
    <ChakraHeading
      as="h1"
      fontSize={{ base: '32px', md: '42px' }}
      fontWeight="extrabold"
      lineHeight="1"
      {...props}
    />
  );
};
