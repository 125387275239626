import { Link } from '@/ui';
import { Card, CardBody } from '../components/Card';
import { Heading } from '../components/Heading';

export const NotFound = () => {
  return (
    <Card maxW="800px" mx="auto" textAlign="center">
      <CardBody flexDir="column" gap="4">
        <Heading fontSize="2xl">Results not found</Heading>
        <Link colorScheme="dark" href="/magic" textDecor="underline">
          Return home
        </Link>
      </CardBody>
    </Card>
  );
};
